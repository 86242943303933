<template>
	<el-dialog destroy-on-close :close-on-click-modal="false" ref="dialog" title="报警消息记录" :visible.sync="show" width="1400px">
		<div>
			<el-table :data="list" border style="width: 100%;" v-loading="loading">
				<el-table-column prop="deviceNo" label="设备编号" ></el-table-column>
				<el-table-column prop="ch4State" label="甲烷传感器状态" ></el-table-column>
				<el-table-column prop="ch4Concentration" label="甲烷浓度(单位%LEL)"></el-table-column>
				<el-table-column prop="coState" label="一氧化碳传感器状态" ></el-table-column>
				<el-table-column prop="coConcentration" label="一氧化碳浓度(单位%PPM)" ></el-table-column>
				<el-table-column prop="content" label="报警消息内容" ></el-table-column>
				<el-table-column prop="createTime" label="报警时间" ></el-table-column>
			</el-table>
		</div>
		<div class="page mg_t text_c">
			<el-pagination 
				@current-change="changePage"
				@size-change="sizeChange"
				:page-sizes="[ 10 , 20 , 30 , 40]" 
				:total="total" 
				:currentPage="form.startPage" 
				:pageSize="form.pageSize" 
				background
				layout="total, sizes, prev, pager, next, jumper"
			>
			</el-pagination>
		</div>
	</el-dialog>
</template>

<script>
	export default{
		data(){
			return {
				loading: false,
				form : {
					startPage : 1,
					pageSize : 10,
					deviceNo : "",
				},
				total : 0,
				show : false,
				list:[]
			}
		},
		methods: {
			open( data ){
				this.show = true;
				this.form.deviceNo = data.deviceNo
				this.messageList()
			},
			changePage( e ){
				this.form.startPage = e;
				this.messageList();
			},
			sizeChange( e ){
				this.form.pageSize = e;
				this.messageList();
			},
			messageList(){
				this.loading = true;
				this.$api.alarm_list( this.form ).then( res => {
					if( res.code == 200 ){
						this.list = res.data.list;
						this.form.startPage = res.data.page;
						this.total = res.data.totalNum;
						this.loading = false;
						for( var i = 0 ; i < this.list.length ; i++ ){
							for( var k in this.list[i] ){
								if( this.list[i][k] == null || this.list[i][k] == "" ){
									this.list[i][k] = "—"
								}
							}
						}
					}else{
						this.list = [];
						this.form.startPage = 1;
						this.total = 0;
						this.loading = false;
					}
				});
			}
		},
	}
</script>

<style lang="scss">
	.box {
		height: 25px;
	}

	.el-dialog__header {
		background: linear-gradient(135deg, #6b6be4, #15d8f1);
	}

	.el-dialog__title {
		color: #fff
	}

	.el-dialog__header {
		.el-dialog__headerbtn {
			.el-dialog__close {
				color: #fff;
			}
		}
	}
</style>