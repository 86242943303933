<template>
	<el-dialog destroy-on-close :close-on-click-modal="false" ref="dialog" title="家庭成员" :visible.sync="show" width="600px">
		<div>
			<el-table :data="list" border style="width: 100%;" v-loading="loading">
				<el-table-column prop="nickName" label="用户昵称" ></el-table-column>
				<el-table-column prop="creator" label="成员角色" >
					<template slot-scope="scope">
						<el-tag size="mini" :type=" scope.row.creator == 1 ? 'danger' : 'primary' " disable-transitions>
							{{ scope.row.creator == 1 ? '创建者' : '成员' }}
						</el-tag>
					</template>
				</el-table-column>
				<el-table-column prop="phone" label="联系电话"></el-table-column>
			</el-table>
		</div>
	</el-dialog>
</template>

<script>
	export default{
		data(){
			return {
				loading: false,
				show : false,
				list:[]
			}
		},
		methods: {
			open( data ){
				this.show = true;
				this.familyList( data )
			},
			familyList( data ){
				this.loading = true;
				this.$api.home_user( { homeId : data.homeId } ).then( res => {
					if( res.code == 200 ){
						this.list = res.data;
						this.loading = false;
						for( var i = 0 ; i < this.list.length ; i++ ){
							for( var k in this.list[i] ){
								if( this.list[i][k] == null || this.list[i][k] == "" ){
									this.list[i][k] = "—"
								}
							}
						}
					}else{
						this.list = [];
						this.loading = false;
					}
				});
			}
		},
	}
</script>

<style lang="scss">
	.box {
		height: 25px;
	}

	.el-dialog__header {
		background: linear-gradient(135deg, #6b6be4, #15d8f1);
	}

	.el-dialog__title {
		color: #fff
	}

	.el-dialog__header {
		.el-dialog__headerbtn {
			.el-dialog__close {
				color: #fff;
			}
		}
	}
</style>