<template>
	<div>
		<el-form :inline="true" :model="form">
			<el-form-item label="设备编号">
				<el-input v-model="form.deviceNo" placeholder="请输入设备编号"></el-input>
			</el-form-item>
			<el-form-item label="时间范围">
			  <el-date-picker
			      value-format="yyyy-MM-dd"
			      v-model="date_value"
			      type="daterange"
			      range-separator="至"
			      start-placeholder="开始日期"
			      end-placeholder="结束日期">
			  </el-date-picker>
			</el-form-item>
			<el-form-item>
				<el-button @click="alarm_message()" type="primary">查询</el-button>
			</el-form-item>
		</el-form>
	<!-- 	<el-row class="mg_b text_r">
			<el-button type="primary" icon="el-icon-download" @click="alarm_export()" style="margin-right: 10px;">导出</el-button>
		</el-row> -->
		<el-table :data="list" border style="width: 100%" v-loading="loading">
			<el-table-column prop="deviceNo" label="设备编号" ></el-table-column>
			<el-table-column prop="content" label="消息内容" ></el-table-column>
			<el-table-column prop="status" width="120" label="是否已读" >
				<template slot-scope="scope">
					<el-tag size="mini" :type=" scope.row.status == 1 ? 'success' : 'danger' " disable-transitions>
						{{ scope.row.status == 1 ? '已读' : '未读' }}
					</el-tag>
				</template>
			</el-table-column>
			<!-- <el-table-column prop="isRead" label="是否已读"></el-table-column> -->
			<!-- <el-table-column prop="type" label="发送方式" ></el-table-column> -->
			<el-table-column prop="type" width="120" label="发送方式" >
				<template slot-scope="scope">
					<el-tag size="mini" :type=" scope.row.type == 1 ? 'info' : '' " disable-transitions>
						{{ scope.row.type == 1 ? '语音' : '短信' }}
					</el-tag>
				</template>
			</el-table-column>
			<!-- <el-table-column prop="homeId" label="家庭id" ></el-table-column> -->
			<el-table-column prop="homeName" label="家庭名称" ></el-table-column>
			<el-table-column prop="sendTime" label="发送时间" ></el-table-column>
			<el-table-column label="操作" fixed="right" width="140">
				<template slot-scope="scope">
					<el-button slot="reference" size="mini" type="primary" icon="el-icon-share" @click="alarmRecord(scope.row)" style="margin-right: 5px;">报警记录</el-button>
					<el-button slot="reference" size="mini" type="success" icon="el-icon-view" @click="seeFamily(scope.row)" style="margin-right: 5px;">查看成员</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div class="page mg_t text_c">
			<el-pagination @current-change="changePage" @size-change="sizeChange" :page-sizes="[ 10 , 20 , 30 , 40]"
				:total="total" :currentPage="form.startPage" :pageSize="form.pageSize" background
				layout="total, sizes, prev, pager, next, jumper">
			</el-pagination>
		</div>
		<familyList ref="familyListData"/>
		<messageRecordList ref="messageRecordListData" />
		<el-drawer
			title="家庭成员"
			:visible.sync="drawer"
			:direction="direction"
			size="20%" >
			<div class="demo-basic--circle" style="display: flex;justify-content: start;">
				<div class="block" v-for="( item , i ) in familyList" :key="i" style="padding: 0px 30px 30px 30px;width: 60px;position: relative;">
					<el-avatar :size="60" :src=" item.avatarUrl == '' ? 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png' : item.avatarUrl "></el-avatar>
					<div style="text-align: center;" :class=" item.creator == 1 ? 'blue' : 'orange' ">{{ item.creator == 1 ? "创建者" : "成员" }}</div>
					<div style="text-align: center;font-size: 13px;position: absolute;width: 100%;margin-left: -30px;">{{ item.nickName }}</div>
				</div>
			</div>	
		</el-drawer>
	</div>
</template>

<script>
	import familyList from './components/familyList.vue';
	import messageRecordList from './components/messageRecordList.vue';
	export default {
		data() {
			return {
				loading: false,
				form: {
					startPage: 1,
					pageSize: 10,
					deviceNo:'',
					startDate:'',
					endDate:''
				},
				total: 0,
				list: [],
				date_value:''
			}
		},
		created() {
			this.alarm_message();
		},
		mounted() {

		},
		methods: {
			alarm_message() {
				this.loading = true;
				if( this.date_value != "" && this.date_value != null && this.date_value != undefined ){
					this.form.startDate = this.date_value[0];
					this.form.endDate = this.date_value[1];
				}else{
					this.form.startDate = "";
					this.form.endDate = "";
				}
				this.$api.messageForm_list(this.form).then(res => {
					if (res.code == 200) {
						this.list = res.data.list;
						// console.log( this.list )
						this.form.startPage = res.data.page;
						this.total = res.data.totalNum;
						this.loading = false;
					} else {
						this.list = [];
						this.form.startPage = 1;
						this.total = 0;
						this.loading = false;
					}
				});
			},
			changePage(e) {
				this.form.startPage = e;
				this.alarm_message();
			},
			sizeChange(e) {
				this.form.pageSize = e;
				this.alarm_message();
			},
			seeFamily( data ){
				if( !data.homeId ){
					this.$message({
						type: 'error',
						message: '此记录无相关家庭信息'
					});
					return
				}
				this.$refs.familyListData.open(data);
			},
			alarmRecord( data ){
				if( !data.deviceNo ){
					this.$message({
						type: 'error',
						message: '此记录无设备编号'
					});
					return
				}
				this.$refs.messageRecordListData.open(data);
			}
		},
		components:{
			familyList,messageRecordList
		}
	}
</script>